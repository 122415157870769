<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <table-tariff-setting-performer
        :tariffPerformerSetting="tariffPerformerSetting"
        @timerAfto="timerAfto"
        @editForm="editForm"
        @copyTariffPerformer="copyTariffPerformer"
        @deleteTariffSetting="deleteTariffSetting"
        @getPrice="getPrice"
      />
    </div>
    <modal-add-setting
      :id="id"
      :order_types="order_types"
      :performer_status="performer_status"
      :weekdays="weekdays"
      :order_tariffs="order_tariffs"
      @refresh="refresh"
    ></modal-add-setting>
    <modal-edit-setting
      :id="id"
      :idModal="idModal"
      :order_types="order_types"
      :performer_status="performer_status"
      :weekdays="weekdays"
      :order_tariffs="order_tariffs"
      @refresh="refresh"
    ></modal-edit-setting>
    <modal-copy-setting
      :id="id"
      :idModal="idModal"
      :order_types="order_types"
      :performer_status="performer_status"
      :weekdays="weekdays"
      :order_tariffs="order_tariffs"
      @refresh="refresh"
    ></modal-copy-setting>
    <modal-delete-setting
      :id="id"
      :idModal="idModal"
      @refresh="refresh"
    ></modal-delete-setting>
  </div>
</template>

<script>
import tableTariffSettingPerformer from "@/views/component/Table/tableTariffSettingPerformer.vue";
import ModalAddSetting from "@/views/component/Modal/ModalTariffSetting/ModalAddSetting.vue";
import ModalEditSetting from "@/views/component/Modal/ModalTariffSetting/ModalEditSetting.vue";
import ModalCopySetting from "@/views/component/Modal/ModalTariffSetting/ModalCopySetting.vue";
import ModalDeleteSetting from "@/views/component/Modal/ModalTariffSetting/ModalDeleteSetting.vue";
export default {
  components: {
    tableTariffSettingPerformer,
    ModalAddSetting,
    ModalEditSetting,
    ModalCopySetting,
    ModalDeleteSetting,
  },
  data() {
    return {
      id: this.$route.query.allowances_id,
      idModal: null,
      showPreloader: false,
      tariffPerformerSetting: [],
      pr_types: [],
      order_types: [],
      order_tariffs: [],
      performer_status: [],
      weekdays: [],
      timer: null,
    };
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.refresh(this.id);
    this.getPerformerDataSetting();
  },
  methods: {
    refresh(id) {
      this.showPreloader = true;
      this.$http.get(`tariff-performers/base/${id}/settings`).then((res) => {
        this.tariffPerformerSetting = res.data;
        this.$store.commit(
          "pageData/setdataCount",
          this.tariffPerformerSetting.length
        );
        this.showPreloader = false;
        this.$store.commit("REFRESH_DATA", false);
      });
    },
    getPerformerDataSetting() {
      this.$http.get("tariff-performers/base/settings/data").then((res) => {
        this.pr_types = res.data.pr_types;
        this.order_types = res.data.order_types;
        this.order_tariffs = res.data.order_tariffs;
        this.performer_status = res.data.performer_status;
        this.weekdays = res.data.weekdays;
      });
    },
    refreshIs_Active() {
      this.$http
        .get(`tariff-performers/base/${this.id}/settings`)
        .then((res) => {
          this.tariffPerformerSetting = res.data;
        });
    },
    timerAfto(is_active) {
      is_active
        ? (this.timer = setInterval(() => this.refreshIs_Active(), 30000))
        : clearInterval(this.timer);
    },
    editForm(id) {
      this.idModal = id;
      setTimeout(this.getOpenModalEdit, 0);
    },
    getOpenModalEdit() {
      this.$bvModal.show("EditTariffSettingsPerformer");
    },
    copyTariffPerformer(id) {
      this.idModal = id;
      setTimeout(this.getOpenModalCopy, 0);
    },
    getOpenModalCopy() {
      this.$bvModal.show("CopyTariffSettingPerformer");
    },
    deleteTariffSetting(id) {
      this.idModal = id;
      this.$bvModal.show("deleteTariffSetting");
    },
    getPrice() {
      this.$router.push({ name: "tariff/prices", query: { filter: this.id } });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Настройки цены тарифа " + this.id,
        `/tariff/prices`,
        this.id,
      ]);
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      if (val) {
        this.$http
          .get(`tariff-performers/base/${this.id}/settings`)
          .then((res) => {
            this.tariffPerformerSetting = res.data;
            this.$store.commit(
              "pageData/setdataCount",
              this.tariffPerformerSetting.length
            );
            this.$store.commit("REFRESH_DATA", false);
          });
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>
